import logoImg from "images/rastrelog.png";
var logo = document.getElementById("logoImg");
logo.src = logoImg;

import videoMonitoreoDescImg from "images/videoMonitoreoDesc.jpg";
var videoMonitoreoDesc = document.getElementById("videoMonitoreoDescImg");
videoMonitoreoDesc.src = videoMonitoreoDescImg;

import movilImg from "images/movil.png";
var movil = document.getElementById("movilImg");
movil.src = movilImg;

import gabinetesImg from "images/gabinetes.png";
var gabinetes = document.getElementById("gabinetesImg");
gabinetes.src = gabinetesImg;

import sensoresImg from "images/sensores.png";
var sensores = document.getElementById("sensoresImg");
sensores.src = sensoresImg;

import cocheImg from "images/coche.jpg";
var coche = document.getElementById("cocheImg");
coche.src = cocheImg;

import camionesImg from "images/camiones.jpg";
var camiones = document.getElementById("camionesImg");
camiones.src = camionesImg;

import motoImg from "images/moto.jpg";
var moto = document.getElementById("motoImg");
moto.src = motoImg;

import faviconImg from "../favicon.png";
var favicon = document.getElementById("faviconImg");
favicon.href = faviconImg;

// import faviconicoImg from '../favicon.ico';
// var faviconico = document.getElementById('faviconicoImg');
// faviconico.href = faviconicoImg;
