
$(document).ready(function () {


    $('#chevronClick').on('click', function (e) {
        e.preventDefault();

        var target = '#Producto1',
            // menu = target;
            $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top + 2
        }, 500, 'swing', function () {
            window.location.hash = target;
            $(document).on("scroll", onScroll);
        });
    });


    $(document).on("scroll", onScroll);
    //smoothscroll


    $('.nav-link').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");

        $('.nav-link').each(function () {
            $('.nav-item').removeClass('active');
        });
        $(this).parent().addClass('active');

        $(".collapse").removeClass("show");
        $(".navbar-toggler").attr("aria-expanded", false);

        var target = this.hash,
            // menu = target;
            $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top + 2
        }, 500, 'swing', function () {
            window.location.hash = target;
            $(document).on("scroll", onScroll);
        });
    });
});

function onScroll() {

    var topoBody = $("body").scrollTop();
    var topoHtml = $("html").scrollTop();

    if ( topoBody >= 100 || topoHtml >= 100) {
        $(".navbar-topo").addClass("navbar-small");

    }
    else {
        $(".navbar-topo").removeClass("navbar-small");
        $(".navbar-topo").addClass(".navbar-topo");
    }


    var scrollPos = $(document).scrollTop();
    $('.nav-link').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top - 5 <= scrollPos && refElement.position().top + refElement.outerHeight() > scrollPos) {
            $('.nav-item').removeClass("active");
            currLink.parent().addClass("active");
        }
        else {
            currLink.parent().removeClass("active");
        }
    });
}


//PHP EMAIL BEGIN

$('#enviar').click(function () {
    var nome = $('#nome').val();
    var email = $('#email').val();
    var assunto = $('#assunto').val();
    var mensagem = $('#mensagem').val();
    $.post('./assets/phpmailer/email.php',
        {nome: nome, email: email, mensagem: mensagem, assunto: assunto},
        function (data, textStatus) {
        });
    $('#nome').val("");
    $('#email').val("");
    $('#assunto').val("");
    $('#mensagem').val("");
    alert("Email enviado com sucesso!");
    return false;
});

//PHP EMAIL END
